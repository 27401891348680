<template>
  <div class="table" v-if="columns.length > 0 && datas.length > 0 && len > 2">
    <div class="tab-row first-line">
      <div
        :class="['tab-col tab-col-c', `tab-col-w-${tabConfig[len - 2][index]}`]"
        v-for="(title, index) in columns"
        :key="index"
      >
        {{ title }}
      </div>
    </div>
    <div
      :class="[
        'tab-row',
        isEnd && index === datas.length - 1 ? 'last-line' : '',
      ]"
      v-for="(row, index) in datas"
      :key="'row' + index"
      @click="handleClick(row)"
    >
      <div
        :class="[
          'tab-col tab-col-c',
          `tab-col-w-${tabConfig[len - 2][ids]}`,
          ids === 0 && (!isEnd || index !== datas.length - 1)
            ? 'first-col'
            : '',
        ]"
        v-for="(col, ids) in row.datas"
        :key="'col' + ids"
      >
        {{ col }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, watch } from "vue";

export default defineComponent({
  props: {
    columns: {
      type: Array,
      default: [],
    },
    datas: {
      type: Array,
      default: [],
    },
    isEnd: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onRow"],
  setup(props, context) {
    const dataMap = reactive({
      len: props.columns.length,
      tabConfig: [
        [35, 65],
        [30, 35, 35],
        [25, 25, 25, 25],
        [20, 20, 20, 20, 20],
        [25, 15, 15, 15, 15, 15],
      ],
    });

    watch(
      () => props.datas,
      () => {
        dataMap.len = props.columns.length;
      },
      {
        deep: true,
      }
    );

    const handleClick = (item: any) => {
      context.emit("onRow", item);
    };
    return {
      ...toRefs(dataMap),
      handleClick,
    };
  },
});
</script>

<style lang="less" scoped>
</style>