
import { defineComponent, reactive, toRefs, watch } from "vue";

export default defineComponent({
  props: {
    columns: {
      type: Array,
      default: [],
    },
    datas: {
      type: Array,
      default: [],
    },
    isEnd: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onRow"],
  setup(props, context) {
    const dataMap = reactive({
      len: props.columns.length,
      tabConfig: [
        [35, 65],
        [30, 35, 35],
        [25, 25, 25, 25],
        [20, 20, 20, 20, 20],
        [25, 15, 15, 15, 15, 15],
      ],
    });

    watch(
      () => props.datas,
      () => {
        dataMap.len = props.columns.length;
      },
      {
        deep: true,
      }
    );

    const handleClick = (item: any) => {
      context.emit("onRow", item);
    };
    return {
      ...toRefs(dataMap),
      handleClick,
    };
  },
});
