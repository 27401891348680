<template>
  <div class="area">
    <van-nav-bar
      title="区域来单数据"
      :left-arrow="!isTab"
      :right-text="isShowClose ? '关闭' : ''"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="wrapper hidden-scrollbar">
      <div v-show="!isShowClose">
        <simpleTable
          :columns="titles"
          :datas="bodys"
          @onRow="showDetails"
          isEnd
        ></simpleTable>
      </div>
      <div v-show="isShowClose">
        <div
          v-for="(item, index) in chartsDatas"
          :key="index"
          class="p-b-10 cv"
        >
          <cv height="66.67vw" :datas="item"></cv>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRouter } from "vue-router";
import { NavBar } from "vant";
import { global_option, achievement_option } from "@/utils/echartsOption";
import { deepObjectMerges } from "@/utils/app";
import cv from "@/components/chart/echartsTable.vue";
import simpleTable from "@/components/table/simpleTable.vue";

export default defineComponent({
  components: {
    [NavBar.name]: NavBar,
    cv,
    simpleTable,
  },
  setup() {
    const router = useRouter();
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;
    const { totalStatusType, totalStatusYear } = store.state.app.reportConfig!;

    interface Captions {
      cloud: string[];
      soft_fitting: string[];
      experience: string[];
      union: string[];
    }

    interface RowData {
      name: string;
      datas: (number | string)[];
      region: string;
      is_region: number;
    }

    const dataMap = reactive({
      region: "",
      isBack: true,
      isTab: false,
      isShowClose: false,
      captions: {
        cloud: ["区域", "来单(个)", "协议(个)", "签单(个)", "业绩(万)"],
        soft_fitting: ["区域", "来单(个)", "协议(个)", "签单(个)", "业绩(万)"],
        experience: ["区域", "来单(个)", "签单(个)", "业绩(万)"],
        union: ["区域", "签单(个)", "业绩(万)"],
      } as Captions,
      bodys: [] as RowData[],
      chartsDatas: [] as unknown[],
    });

    const titles = dataMap.captions[totalStatusType as keyof Captions];

    const onClickLeft = () => {
      if (dataMap.isBack) {
        router.go(-1);
      } else {
        dataMap.region = "";
        getReportDataList();
        dataMap.isBack = true;
      }
    };
    const onClickRight = () => {
      dataMap.isShowClose = false;
      dataMap.isTab = false;
    };

    const getReportDataList = () => {
      store
        .dispatch("actReportDataList", {
          dept_id: dept_id,
          userid: userid,
          username: username,
          year: totalStatusYear!,
          type: totalStatusType!,
          region: dataMap.region,
        })
        .then((res) => {
          dataMap.bodys = [];
          if (res.status === 200) {
            let total = {
              received_count: 0,
              agreement_count: 0,
              sign_count: 0,
              amount: 0,
            };
            res.data_list.forEach((el) => {
              let tem: RowData = {
                name: el.name,
                datas: [el.name],
                region: "",
                is_region: el.is_region,
              };
              total.received_count += el.received_count;
              total.agreement_count += el.agreement_count;
              total.sign_count += el.sign_count;
              total.amount += el.amount;
              if (
                totalStatusType === "cloud" ||
                totalStatusType === "soft_fitting"
              ) {
                tem.datas.push(el.received_count);
                tem.datas.push(el.agreement_count);
                tem.datas.push(el.sign_count);
                tem.datas.push(el.amount);
                tem.region = el.region;
              } else if (totalStatusType === "experience") {
                tem.datas.push(el.received_count);
                tem.datas.push(el.sign_count);
                tem.datas.push(el.amount);
                tem.region = el.region;
              } else if (totalStatusType === "union") {
                tem.datas.push(el.sign_count);
                tem.datas.push(el.amount);
                tem.region = el.region;
              }
              dataMap.bodys.push(tem);
            });
            let _: RowData = {
              name: "合计",
              datas: [],
              region: "",
              is_region: 0,
            };
            if (
              totalStatusType === "cloud" ||
              totalStatusType === "soft_fitting"
            ) {
              _.datas = [
                "合计",
                total.received_count.toFixed(2),
                total.agreement_count.toFixed(2),
                total.sign_count.toFixed(2),
                total.amount.toFixed(2),
              ];
            } else if (totalStatusType === "experience") {
              _.datas = [
                "合计",
                total.received_count.toFixed(2),
                total.sign_count.toFixed(2),
                total.amount.toFixed(2),
              ];
            } else if (totalStatusType === "union") {
              _.datas = [
                "合计",
                total.sign_count.toFixed(2),
                total.amount.toFixed(2),
              ];
            }
            dataMap.bodys.push(_);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const showDetails = (item: RowData) => {
      if (item.is_region == 1) {
        dataMap.region = item.region;
        dataMap.isBack = false;
        getReportDataList();
        return;
      }
      interface Titles {
        amount_list: string;
        sign_list: string;
        agreement_list: string;
        received_list: string;
      }
      let titles: Titles = {
        amount_list: `${item.name}业绩数据曲线图`,
        sign_list: `${item.name}签单数据曲线图`,
        agreement_list: `${item.name}协议数据曲线图`,
        received_list: `${item.name}来单数据曲线图`,
      };
      store
        .dispatch("actReportDataRegionYearMon", {
          dept_id: dept_id,
          userid: userid,
          username: username,
          region: item.region,
          type: totalStatusType!,
        })
        .then((res) => {
          if (res.status === 200) {
            dataMap.chartsDatas = [];
            for (let key in res.data_list.list) {
              let option = JSON.parse(JSON.stringify(achievement_option));
              option.title = {
                text: titles[key as keyof Titles],
                x: "center",
                y: "5%",
              };
              option.legend = {};
              option.legend["data"] = res.data_list.year_list;
              res.data_list.year_list.forEach((year: string, index: number) => {
                option.series[index].name = year;
                option.series[index].data =
                  res.data_list.list[
                    key as keyof ReportDataRegionYearMonReq["list"]
                  ]![year].split(",");
              });
              option.grid.top = "20%";
              dataMap.chartsDatas.push(
                deepObjectMerges([
                  JSON.parse(JSON.stringify(global_option)),
                  option,
                ])
              );
              dataMap.isShowClose = true;
              dataMap.isTab = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getReportDataList();
    return {
      titles,
      onClickLeft,
      onClickRight,
      showDetails,
      ...toRefs(dataMap),
    };
  },
});
</script>

<style lang="less" scoped>
.wrapper {
  overflow: auto;
  height: calc(100vh - 46px);
  .cv {
    border-bottom: 1px solid #eee;
  }
}
</style>