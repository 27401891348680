import * as  echarts from "echarts"

let colors = ["#91A7D0", "#F5C9C8", "#EB9587", "#786075", "#8F5F50"];
let baseFontSize = 28 * Math.min(window.document.documentElement.clientWidth / 750, 2);


export const saleChartOption = {
  title: {
    text: ''
  },
  tooltip: {
    enterable: true,
    trigger: 'axis',
    formatter: function (params: any) {
      return '<p style="font-size:18px;">' + params[1].name + '年: ' + params[1].value + '</p>';
    },
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#8995CB'
      }
    }
  },
  xAxis: {
    axisLabel: {
      textStyle: {
        color: '#fff'
      }
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: 'rgba(47, 162, 244, 0.5)'
      }
    },
    z: 10
  },
  yAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: 'rgba(47, 162, 244, 0.5)'
      }
    },
    splitLine: {
      show: true,
      //  改变轴线颜色
      lineStyle: {
        // 使用深浅的间隔色
        color: 'rgba(47, 162, 244, 0.1)'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      textStyle: {
        color: '#999'
      }
    }
  },
  grid: {
    left: '0',
    right: '0',
    bottom: '5%',
    containLabel: true
  },
  series: [
    {
      type: 'bar',
      itemStyle: {
        normal: { color: 'rgba(0,0,0,0.05)' }
      },
      barCategoryGap: '40%',
      animation: false,
      barWidth: 5,
    },
    {
      type: 'bar',
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#83bff6' },
              { offset: 0.5, color: '#188df0' },
              { offset: 1, color: '#188df0' }
            ]
          )
        },
        emphasis: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#2378f7' },
              { offset: 0.7, color: '#2378f7' },
              { offset: 1, color: '#83bff6' }
            ]
          )
        }
      },
    }
  ]
};

export const sourceReportOption = {
  title: {
    text: ''
  },
  tooltip: {
    // trigger: "item",
    formatter: "{d}%"
  },
  legend: {
    orient: 'horizontal',
    bottom: 10,
    x: 'left',
    left: '0',
    icon: 'circle',
    textStyle: {
      color: '#8995CB'
    }
  },
  series: [
    {
      type: "pie",
      color: ["#38cb9a", "#43acf7", "#f96f52", "#816bff"],
      center: ["50%", "50%"],
      radius: ["20%", "40%"],
      // 默认防止标签重叠
      avoidLabelOverlap: false,
      startAngle: 400,
      label: {
        normal: {
          // \n\n让文字再视觉引导线的上面
          formatter: "{d}%\n\n",
          padding: [0, -20],
          position: "outer",
          fontSize: "1rem",
          color: '#8995CB'
        }
      },
      markPoint: {
        symbol: 'circle',
        symbolSize: '10'
      },
      labelLine: {
        normal: {
          show: true,
          length: 10,
          length2: 30
        },
        lineStyle: {
          color: '#8995CB'
        }
      },
    },
  ]
};

export const mapReportOption = {
  backgroundColor: 'transparent',
  title: {
    text: '',
    left: 'center',
    textStyle: {
      color: '#fff'
    }
  },
  legend: {
    orient: 'vertical',
    y: 'bottom',
    x: 'right',
    province: ['签单'],
    textStyle: {
      color: '#fff'
    }
  },
  tooltip: {
    trigger: 'item',
    formatter: function (params: any) {
      var info = '<p style="font-size:18px;">' + params.name + '</p>'
      return info;
    },
    backgroundColor: "rgba(0, 0, 0, 0.5)",//提示标签背景颜色
    textStyle: { color: "#fff" } //提示标签字体颜色
  },
  geo: {
    map: 'china',
    label: {
      emphasis: {
        show: false
      }
    },
    roam: true,
    zoom: 1, //当前视角的缩放比例
    itemStyle: {
      normal: {
        areaColor: 'rgba(0, 0, 0, 0.05)',
        borderColor: 'rgba(47, 162, 244, 0.5)'
      },
      emphasis: {
        areaColor: '#2a333d'
      }
    }
  },
  series: [
    {
      type: 'scatter',
      coordinateSystem: 'geo',
      label: {
        normal: {
          show: false,
          color: '#2FA2F4'
          // formatter: '{b}',
          // position: 'right',
        },
        emphasis: {
          show: true
        }
      },
      itemStyle: {
        normal: {
          color: '#2FA2F4'
        }
      }
    },
    {
      type: 'effectScatter',
      coordinateSystem: 'geo',
      showEffectOn: 'render',
      rippleEffect: {
        brushType: 'stroke'
      },
      hoverAnimation: true,
      label: {
        normal: {
          show: false,
          // formatter: '{b}',
          // position: 'right',
        }
      },
      itemStyle: {
        normal: {
          color: '#2FA2F4',
          shadowBlur: 10,
          shadowColor: '#333'
        }
      },
      zlevel: 1
    }
  ]
};

export const growthReportOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  legend: {
    textStyle: {
      color: '#8995CB'
    }
  },
  xAxis: [
    {
      type: 'category',
      data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      axisPointer: {
        type: 'shadow'
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(47, 162, 244, 0.5)'
        }
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: '',
      min: 0,
      max: 150,
      interval: 30,
      axisLabel: {
        formatter: '{value}'
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(47, 162, 244, 0.5)'
        }
      },
      splitLine: {
        show: true,
        //  改变轴线颜色
        lineStyle: {
          // 使用深浅的间隔色
          color: 'rgba(47, 162, 244, 0.1)'
        }
      },
    },
    {
      show: false,
      type: 'value',
      name: '',
      min: 0,
      max: 25,
      interval: 5,
      axisLabel: {
        formatter: '{value}'
      }
    },
    {
      type: 'value',
      name: '',
      min: -5,
      max: 5,
      interval: 5,
      axisLabel: {
        formatter: '{value}'
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(47, 162, 244, 0.5)'
        }
      },
      splitLine: {
        show: true,
        //  改变轴线颜色
        lineStyle: {
          // 使用深浅的间隔色
          color: 'rgba(47, 162, 244, 0.1)'
        }
      }
    }
  ],
  series: [
    {
      name: '2018年',
      type: 'bar',
      itemStyle: {
        color: '#F67A7B'
      },
      barWidth: 5
    },
    {
      name: '2019年',
      type: 'bar',
      itemStyle: {
        color: '#31C2E2'
      },
      barWidth: 5
    },
    {
      name: '增长率',
      type: 'line',
      yAxisIndex: 2,
      itemStyle: {
        normal: {
          color: '#fff',
          lineStyle: {
            color: '#2FA2F4'
          }
        }
      },
    }
  ]
};

export const performanceReportOption = {
  tooltip: {
    enterable: true,
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#8995CB'
      }
    }
  },
  legend: {
    // data:['目标','实际'],
    textStyle: {
      color: '#8995CB'
    }
  },
  xAxis: [
    {
      type: 'category',
      data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      axisPointer: {
        type: 'shadow'
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(47, 162, 244, 0.5)'
        }
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: '',
      min: 0,
      max: 200,
      interval: 40,
      axisLabel: {
        formatter: '{value}'
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(47, 162, 244, 0.5)'
        }
      },
      splitLine: {
        show: true,
        //  改变轴线颜色
        lineStyle: {
          // 使用深浅的间隔色
          color: 'rgba(47, 162, 244, 0.1)'
        }
      }
    }, {
      show: false,
      type: 'value',
      name: '',
      min: 0,
      max: 25,
      interval: 5,
      axisLabel: {
        formatter: '{value}'
      }
    }, {
      type: 'value',
      name: '',
      min: -1,
      max: 1,
      interval: 1,
      axisLabel: {
        formatter: '{value}'
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(47, 162, 244, 0.5)'
        }
      },
      splitLine: {
        show: true,
        //  改变轴线颜色
        lineStyle: {
          // 使用深浅的间隔色
          color: 'rgba(47, 162, 244, 0.1)'
        }
      }
    }
  ],
  series: [
    {
      name: '实际',
      type: 'bar',
      itemStyle: {
        color: '#5775DF'
      },
      barWidth: 5
    },
    {
      name: '目标',
      type: 'bar',
      itemStyle: {
        color: '#816BFF'
      },
      barWidth: 5
    },
    {
      name: '完成效率',
      type: 'line',
      yAxisIndex: 2,
      itemStyle: {
        color: '#fff'
      }
    }
  ]
};

export const global_option = {
  icon: 'path://M7.424 476.672L301.312 778.24s205.312-204.288 425.984 0c3.072 1.024 291.84-301.568 291.84-301.568s-466.944-519.168-1011.712 0',
  color: colors,
  legend: {
    bottom: "bottom",
    itemHeight: 14 * baseFontSize / 14,
    itemWidth: 24 * baseFontSize / 14,
    textStyle: {
      fontSize: 10 / 14 * baseFontSize,
      fontWeight: 400,
    },
  }
}

export const total_option = {
  title: {
    x: 'center'
  },
  tooltip: {
    trigger: 'item',
    formatter: "{b} <br/>{c} ({d}%)"
  },
  series: [{
    type: 'pie',
    radius: '50%',
    center: ['50%', '50%'],
    hoverOffset: 10,
  }
  ]
}

export const achievement_option = {
  icon: 'path://M0 0h10240v1024H0z',
  tooltip: {
    trigger: 'axis'
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    axisLabel: {
      interval: 0,
      rotate: 45,
      padding: [8, 0, 0, 0]
    }
  },
  yAxis: {
    type: 'value',
  },
  grid: {
    top: "5%",
    left: '5%',
    right: '5%',
    bottom: '10%',
    containLabel: true
  },
  series: [
    { type: 'line' }, { type: 'line' }, { type: 'line' }
  ]
}

export const origin_global_option = {
  seriesItemLabel: {
    normal: {
      show: false,
      position: 'center',
      formatter: '{b}\n{d}%'
    },
    emphasis: {
      show: true,
      position: 'center',
      textStyle: { //设置文字样式
        fontSize: 12,
      }
    }
  },
  seriesItemLabelLine: {
    show: false
  }
}

export const origin_option = {
  title: [
    {
      x: document.documentElement.clientWidth * 0.18 - 2 * parseFloat(baseFontSize + ""), y: "10%", textStyle: {
        fontSize: 18 / 14 * parseFloat(baseFontSize + "")
      }
    },
    {
      x: 'center', y: "10%"
    },
    {
      x: document.documentElement.clientWidth * 0.82 - 2 * parseFloat(baseFontSize + ""), y: "10%", textStyle: {
        fontSize: 18 / 14 * parseFloat(baseFontSize + "")
      }
    },
  ],
  tooltip: {
    trigger: 'item',
    formatter: "{b} <br/>{c}个 ({d}%)"
  },
  series: [
    {
      type: 'pie',
      center: ['18%', '50%'],
      radius: "25%",
      labelLine: {
        show: false,
      },
      // itemStyle: {
      //   normal: {
      label: {
        padding: [10, -30, 0, -30],
        formatter: '{d}%',
        color: '#000',
        fontStyle: {
          fontSize: 10
        }
      }
      //   }
      // }
    },
    {
      type: 'pie',
      center: ['50%', '50%'],
      radius: "25%",
      labelLine: {
        show: false,
      },
      // itemStyle: {
      // normal: {
      label: {
        padding: [10, -30, 0, -30],
        formatter: '{d}%',
        color: '#000',
        fontStyle: {
          fontSize: 10
        }
        //   }
        // }
      }
    },
    {
      type: 'pie',
      center: ['82%', '50%'],
      radius: "25%",
      labelLine: {
        show: false,
      },
      // itemStyle: {
      //   normal: {
      label: {
        padding: [10, -30, 0, -30],
        formatter: '{d}%',
        color: '#000',
        fontStyle: {
          fontSize: 10
        }
      }
      //   }
      // }
    }
  ]
}

export const site_option = {
  series: [
    { type: "bar" }, { type: "bar" }, { type: "bar" }, { type: "bar" }
  ],
  tooltip: {
    trigger: 'axis',
    align: 'center',
    textStyle: {
      align: 'left'
    },
    axisPointer: {
      type: 'shadow'
    }
  },
  xAxis: [{
    type: 'category',
    axisTick: { show: false },
  }],
  yAxis: [
    {
      type: 'value',
    }
  ],
  label: {
    show: true,
    position: "top",
    fontSize: 10,

  },
  grid: {
    left: '5%',
    right: '5%',
    top: '2%',
    containLabel: true
  }
}

export const complaints_option = {
  tooltip: {
    show: true,
    type: 'item'
  },
  xAxis: {
    type: 'value',
    axisLine: {
      show: true
    }
  },
  yAxis: {
    type: 'category',
    splitLine: { show: true },
  },
  grid: {
    height: '67%',
    left: '8%',
    right: '8%',
    bottom: '16.7%',
    containLabel: true,
  },
  series: [
    {
      type: 'bar',
      markLine: {
        data: [{
          type: "max", name: "最大值"
        }],
        symbol: ['none', 'arrow'],
        symbolSize: [6, 6],
      },
      color: "#91A7D0",
      // itemStyle: {
      // normal: {
      label: {
        show: true,
        position: 'right'
      }
      // }
      // }
    },
    {
      type: 'bar',
      markLine: {
        data: [{
          type: "max", name: "最大值"
        }],
        symbol: ['none', 'arrow'],
        symbolSize: [6, 6],
      },
      color: "#F5C9C8",
      // itemStyle: {
      // normal: {
      label: {
        show: true,
        position: 'right'
      }
      // }
      // }
    },
    {
      type: 'bar',
      markLine: {
        data: [{
          type: "max", name: "最大值"
        }],
        symbol: ['none', 'arrow'],
        symbolSize: [6, 6],
      },
      color: "#EB9587",
      // itemStyle: {
      // normal: {
      label: {
        show: true,
        position: 'right'
      }
      // }
      // }
    }
  ]
}
